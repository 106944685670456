
import React,{useState,useEffect,useContext} from 'react'
import {useParams, useNavigate, Link} from 'react-router-dom'
import { List, Avatar, Typography } from 'antd';
import {UserContex} from '../App'
import Nav from './Nav'
import Footer from './Footer'
import Iframe from 'react-iframe'







function SingleDoubtTeacher() {

    const history = useNavigate()
    const {postId} = useParams()
    const [post,setPost] = useState([])
    const [text, setText] = useState("")

   


    useEffect(()=>{

        fetch(`/singledoubt/${postId}`,{
            headers:{
                Authorization: "Bearer " +localStorage.getItem("jwt")
                
            }
        }).then(res=>res.json())
        .then(result=>{
          setPost(result.post)

          console.log(result.post)
        })
         
       
        
        
        


    },[])



    const addCourse = ()=>{

        fetch('/addCourse',{
            method:"put",
            headers:{
                "Authorization":"Bearer "+localStorage.getItem("jwt"),
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                courseId:postId
                
            })
        }).then(res=>res.json())
            .then(result=>{
                
            
               }).catch(err=>{
                   console.log(err)
               })


    }


    const makeComment = ()=>{

        fetch('/teacheranswer',{
            method:"put",
            headers:{
                "Authorization":"Bearer "+localStorage.getItem("jwt"),
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                postId,
                studenttext:text,
            })
        }).then(res=>res.json())
            .then(result=>{

                window.location.reload(false)

                
                const newData = post.map(item=>{
                    if(item._id == result._id){
                        return result
                    }else{
                        return item
                    }

                    
                })
                setPost(newData)

               }).catch(err=>{
                   console.log(err)
               })
    }





    return (
        <div>
                     {/* Nav */}
                     <Nav/>
            {/* end Nav */}
            <header className="bg-white shadow">
                 <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-row">
                     <h1 className="text-3xl font-bold text-gray-900">Start Teaching</h1>
                     <button type="button" class="py-2 ml-2 px-12 bg-Our_purple_dark  text-black w-500px transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg text-white"
                     
                     onClick={()=>addCourse()}
                     >
                         Bookmark 
                    </button>
                  
                </div>
            </header>



                {post?  <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {/* Add content here */}
                <h1 class="font-bold text-4xl text-center ">{post.title}</h1>
                <p class="  text-center text-gray-400">{}</p>
                <p class="  text-center text-gray-400 my-8">{post.body}</p>

                    {/* Video Loads here */}

                    {/* <Iframe url="localhost:1111/video/video-1624198809231.mov" 
        width="100%"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/> */}


            <img class="h-72 w-full" src={`http://localhost:1111/${post.url}`} />

          


                <div className="max-w-7xl h-full bg-white shadow-md mx-auto py-6 sm:px-6 lg:px-8">
                <div className="col-span-6 sm:col-span-3 flex flex-row justify-center">
                      
                      

                      
                      <textarea
                        type="text"
                        placeholder="Ask A Question"
                        className="appearance-none border rounded-xl border-gray-300 w-2/3 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                        value={text}
                        onChange={(e)=>setText(e.target.value)}


                      />
                          <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white bg-Our_purple_dark hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                        onClick={()=>makeComment()}
                  >
                    Send
                  </button>
                    </div>
                </div> 



                <div class="px-4 py-5 border-b rounded-t sm:px-6">
    <div class="overflow-hidden bg-white shadow  sm:rounded-md">
        <ul class="divide-y divide-gray-200">

        {
                                post.comments ?

                                post.comments.map(item=>{

                                    return(

                                        <li>
                                                                                      {item.student?

                                          <a class="block  hover:bg-gray-50 ">

                                             <div class="px-4 py-4 sm:px-6">
                                             <div class="flex items-center justify-between">
                                                 <p class="text-gray-700 text-md  md:truncate">
                                                   
                                                   {item.student.studenttext}
                                                                                                     </p>
                                                 <div class="flex flex-shrink-0 ml-2">
                                                     <p class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                                         Student
                                                     </p>
                                                 </div>
                                             </div>
                                             <div class="mt-2 sm:flex sm:justify-between">
                                                 <div class="sm:flex">
                                                     <p class="flex items-center font-light text-gray-500 text-md ">
                                                     By: {item.student.postedBy.name}
                                                     </p>
                                                 </div>
                                             </div>
                                         </div>
                                         
                                       
                                         
                                      </a>
                                      :
                                      <a class="block  hover:bg-gray-50 ">

                                      <div class="px-4 py-4 sm:px-6">
                                      <div class="flex items-center justify-between">
                                          <p class="text-gray-700 text-md  md:truncate">
                                            
                                            {item.teacher.teachertext}
                                                                                              </p>
                                          <div class="flex flex-shrink-0 ml-2">
                                              <p class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                                  Teacher
                                              </p>
                                          </div>
                                      </div>
                                      <div class="mt-2 sm:flex sm:justify-between">
                                          <div class="sm:flex">
                                              <p class="flex items-center font-light text-gray-500 text-md ">
                                              {item.teacher.answeredBy.name}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  
                                
                                  
                               </a>
                                        }
                                        
                                      
                                    </li>
                                    )

                                }): null

                            }
          
        
          
        </ul>
   
    </div>
</div>

            </div>  :<h1>Loading.....</h1>

    }


          
           

            {/* Footer */}
            <Footer/>
            {/* end Footer */}
            
        </div>
            
    
    )
}

export default SingleDoubtTeacher
