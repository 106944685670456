import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
import SearchIcon from '@mui/icons-material/Search';
import {UserContex} from '../App'
import Nav_men from './Nav_men';


function SubjectTeacher() {

  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const [data, setData] = useState([])
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)




  useEffect(()=>{

    fetch('/getSubject',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.post)
  })


  },[])



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-post',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setUserDetails(results.user)
      })


  }
    


    return (
        <div>
             {/* Nav */}
            <Nav_men/>
            {/* end Nav */}
            <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Search For Subject</h1>
          <div class="flex relative ">
          <span class="mt-2 inline-flex  items-center px-3 text-sm">
            <SearchIcon/>        
    </span>

        
    
    <input type="text" id="" class=" mt-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="" placeholder="Enter Search"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}
    
    />
    
    </div>
        </div>

        
        
      </header>
      <main class="mb-6">
            <div class="sm:flex flex-wrap justify-center items-center text-center gap-2 bg-white p-2 " >
              {/* dynamicaaly load code here */}

              {
                data?data.map(item=>{

                  return(


                    <Link to={{ pathname: `/cleardoubts/${item._id}`, query: { subject: item.subject, grade:item.grade } }}>

                    
                
<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
    <a href="#" class="block w-full h-full">
        <img alt="blog photo" src="https://images.unsplash.com/photo-1573591276084-47884b4335f4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2NpZW5jZSUyMGJvb2t8ZW58MHx8MHx8fDA%3D" class="object-cover w-full max-h-40"/>
        <div class="w-full p-4 bg-white ">
            <p class="font-medium text-indigo-500 text-md">
            </p>
            <p class="mb-2 text-xl font-medium text-gray-800 ">
               Class {item.grade}
            </p>
            <p class="font-light text-gray-400  text-md">
                Subject: {item.subject}
            </p>
        </div>
    </a>
</div>

            </Link>
                  )

                }):<></>
              }

            </div>

      </main>
            {/* Footer */}
            <Footer/>
            {/* end Footer */}
        </div>
    )
}

export default SubjectTeacher
