
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import {socket} from "../App"
import Footer from '../components/Footer';
import Nav from './Nav';
 


const SingleDzongkha = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [policy,setPolicy] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [date, setDate] = useState(new Date())
  const [eventDate, seteventDate] = useState(new Date().setHours(0, 0, 0, 0))
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {dzongkhaid} = useParams()
// const elements = useElements()



useEffect(()=>{

    fetch(`/singledzongkhasessions/${dzongkhaid}`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.post)

      console.log(result.post)
    })
     

},[])

 

const startChat =()=>{

    fetch('/addstudentsdzongkhasessions',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        date
      })

    }).then(res=> res.json())
      .then(results=>{

        history('/StudentDashboard')
        
      })



  }

       

    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            <Nav/>

            {/* <Header></Header> */}

            


<div class="relative md:block lg:block hidden">

{/* <img src="bann.jpeg" class="absolute h-full w-full object-cover"/> */}
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 {/* <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
            Elevate Your Events, Simplify Your Tickets
                <br/>
                <br/>
                <br/>
                
          
            </h1>
           
        </div>
    </div>
    */}
   
    </div>


    



        </div>
     

    </div>



  

   
<div class="flex flex-col ">

<img src="/wall.png" class="absolute h-1/2 w-full md:object-cover object-fit overflow-hidden  "/>
{/* 
<video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
<source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
</video> */}
<div>
<img 
  src={data.url 
    ? data.url[0].replace('/upload/', '/upload/e_bgremoval/') 
    : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
  className="relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80 md:mt-24 mt-32 rounded-lg" 
/>
</div>





 {/* <div class=" relative flex  mx-auto">

         <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
 </div>
  */}







{/* <div class="w-full md:p-12  bg-white overflow-auto mt-4 mb-4 relative">


{!(date>=eventDate)? 

<>
<p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 1/3: Book Ticket(s)
                  </p>
<div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
      <div class="flex items-center justify-between h-full">
         
          <div class="flex flex-col justify-between h-full">
         
              <div>
               
  <div class="flex items-center">
                                                    <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100"
                                                    onClick={()=>{addToCart(seats-1);}}
                                                    disabled={(data.row&&data.col)|| (seats==0)}

                                                    >
                                                   
                                                    -
                                                    </button>
                            
                                                    <button type="button" class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                                    {seats}
                                                    </button>
                            
                            
                                                    <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100"
                                                    
                                                    onClick={()=>{addToCart(seats)}}
                                                    disabled={(data.row&&data.col) }
                                                    >
                                                 
                                                    +
                                                    </button>
                                                    </div>
              </div>
              <div>
             
                  <div class="w-full h-4 mt-3 bg-gray-300 rounded-full">
                      <div class=" h-full text-center text-xs text-white bg-indigo-500 rounded-full"
                      style={{ width: `${(size*100)/data.seats}%` }}
                      >
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex flex-col justify-between h-full">
              <p class="text-4xl font-bold text-gray-900 ">
                  <span class="text-sm">
                      $
                  </span>
                  {data.price*seats}
                  
                                           <span class="text-sm text-gray-300">
                      
                  </span>
              </p>
              <button
              onClick={()=>{setPayment(true);handleButtonClick()}}
              disabled={data.occupied>=data.seats || seats<=0}
              type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                 {data.occupied>=data.seats?"Housefull":"Book Ticket" } 
              </button>
              {seats<=0?"Please Select Seats to Proceed":""}
          </div>
      </div>
  </div>
</>
:
  
  <div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
<div class="flex justify-center items-center ">
     
      <img src='/ended.png' class="h-24 w-24 "/>
    
  </div>
</div>
  }



<div class="flex items-end justify-between m-4 header">
 <div class="title">
     <p class="mb-4 text-4xl font-bold text-gray-800">
         {data.name}
     </p>
     <p class="text-2xl font-light text-gray-400">
     {data.description}

     </p>
 </div>


 </div>
 
</div> */}


<div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 mt-2 mb-4">
    <div class="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg pricing-box lg:max-w-none lg:flex">
        <div class="px-6 py-8 bg-gray-50 dark:bg-gray-800 lg:flex-shrink-1 lg:p-12">
            <h3 class="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
                {data?data.title:""}
            </h3>
            <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
                {data?data.body:""}
            </p>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        What&#x27;s included
                    </h4>
                    <div class="flex-1 border-t-2 border-gray-200">
                    </div>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            All illimited components
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Own custom Tailwind styles
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Unlimited Templates
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Free premium dashboard
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Best ranking
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Prenium svg
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            My 
                        </p>
                    </li>
                </ul>
            </div>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        &amp; What&#x27;s not
                    </h4>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="w-6 h-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            No Contracts. No monthly, setup, or additional payment processor fees
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="w-6 h-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            No 2-week on-boarding, it takes 20 minutes!
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="px-6 py-8 text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                Fee
            </p>
            <div class="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900 dark:text-white">
                <span>
                    ${data.fee}
                </span>
            </div>
            <p class="mt-4 text-sm leading-5">
                <span class="block font-medium text-gray-500 dark:text-gray-400">
                    Card payments:
                </span>
                <span class="inline-block font-medium text-gray-500  dark:text-gray-400">
                    2.9% + 20p per transaction
                </span>
            </p>
            <div class="mt-6">
                <div class="rounded-md shadow">
                    <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    onClick={()=>{startChat()}}
                    >
                        Enroll Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>



{
policy? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 {/*content*/}
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   {/*header*/}
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPolicy(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
   {/*body*/}
  
  <h1 className='text-lg text-center'>
  Refund and Cancellation Policy

  </h1>


<p className='text-gray-400 text-sm m-2'>
Thank you for purchasing tickets through B5 Events. Please review our refund and cancellation policy carefully:

No Refunds for Missed Events: We do not offer refunds if you do not attend the event. All ticket sales are final.

Ticket Delivery Issues: If you do not receive your tickets after purchase, please contact us immediately. Send an email to info@b5events.com with your order details, and we will assist in resolving the issue.

No Refund for Non-Receipt of Tickets Without Prior Contact: Failure to receive tickets without informing us will not be eligible for a refund. It is your responsibility to notify us if there is an issue.

Event Cancellations: In the rare event of a cancellation by B5 Events, a full refund will be provided.

By making a purchase, you agree to these terms.

</p>







 

</div>


</div>
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}


</div>
        














<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default SingleDzongkha;