import Card from '@material-tailwind/react';
import CardBody from '@material-tailwind/react';



export default function StatusCard() {
    return (
        <div className="w-full  px-4 flex justify-center text-center">
            <Card>
                <CardBody>
                    <h1 class="text-4xl mb-4 text-Our_purple_dark font-bold">SHEYOEN</h1>
                    <p class="text-2xl text-gray-400 font-bold"> A Platform For Learners and Educators</p>
                </CardBody>
            </Card>
        </div>
    );
}
