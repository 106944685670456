import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
import SearchIcon from '@mui/icons-material/Search';
import {UserContex} from '../App'


function DzongkhaSession() {

  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)


  // const localStorageStudentId = localStorage.getItem('studentId');

  useEffect(()=>{
    const user = localStorage.getItem('user');

    fetch('/getdzongkhasessions',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setUserDetails(result.post)

      const isStudentInArray = (students, id) => {
        return students.some(student => student.student === id);
      };
    
      const filteredData = result.post.filter(item => !isStudentInArray(item.students, user._id));
    
      // Now you can use filteredArray in your component
      setUserDetails(filteredData);

      // console.log(result.post)
  })


  },[])



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-dzongkhasessions',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setUserDetails(results.user)
      })


  }
    


    return (
        <div>
             {/* Nav */}
            <Nav/>
            {/* end Nav */}
            <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Search For Courses</h1>
          <div class="flex relative ">
          <span class="mt-2 inline-flex  items-center px-3 text-sm">
            <SearchIcon/>        
    </span>

        
    
    <input type="text" id="" class=" mt-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="" placeholder="Enter Search"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}
    
    />
    
    </div>
        </div>

        
        
      </header>
      <main>
            <div class="sm:flex flex-wrap justify-center items-center text-center gap-2 bg-gray-200 " >
              {/* dynamicaaly load code here */}

              {
                userDetails.map(item=>{

                  return(


                    <Link to={"/singledzongkha/"+item._id}>

                    
                    <div class="flex items-center justify-center px-5 py-5 m-2">
                <div class="w-full mx-auto max-w-xl rounded-lg bg-white  shadow-lg px-5 pt-5 pb-10 text-gray-800 ">
                    <div class="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                        <a href="#" class="block relative">
                            <img alt="profil" 
                            
                            src="https://images.unsplash.com/photo-1573496799652-408c2ac9fe98?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                            class="mx-auto object-cover rounded-full h-20 w-20 "
                            
                            
                            />
                        </a>
                    </div>


                    <div class="w-full mb-10">
                    < h2 class="text-lg font-bold text-black text-center px-5">
                            {item.title}
                        </h2>
                        <p class="text-sm text-gray-600  text-center px-5">
                            {item.body}
                        </p>
                    </div>
                    <div class="w-full">
                        <p class="text-md text-indigo-500 font-bold text-center">
                        {item.teacher.name}
                        </p>
                        <p class="text-xs text-gray-500  text-center">
                          {item.teacher.email}
                        </p>
                    </div>
                </div>
            </div>
            </Link>
                  )

                })
              }

            </div>

      </main>
            {/* Footer */}
            <Footer/>
            {/* end Footer */}
        </div>
    )
}

export default DzongkhaSession
