
// import LeadText from '@material-tailwind/react/LeadText';

export default function Header() {
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-Landing bg-cover bg-center absolute top-0 w-full h-full" />
         
        </div>
    );
}
