import React, { useEffect, useState,useContext } from 'react'
import Nav_men from "./Nav_men"
import Footer from "./Footer"
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import Select from "react-select";

function ToDo_men() {



    const history = useNavigate();
    const {state, dispatch}= useContext(UserContex)
  
  
    const [weekendsVisible,setWeekendsVisible] = useState(true)
    const[currentEvents,setCurrentEvents]= useState([])
  
    const[data,setData] = useState([]) 
    const[modal,setModal] = useState(false)
  
    const [start,setStartDate] = useState("")
    const [end,setEndDate] = useState("")
    const [title,settitle] = useState("")
    const [description,setDescription] = useState("")
    const [allDay,setallDay] = useState("")
    const [calendarApi,setCalenderAPI] = useState("")
  
    
       const [newPatient,setNewPatient] = useState(false)
     const [name, setName] = useState("")
     const [email, setEmail] = useState("")
     const [phone, setPhone] = useState("")
     const [registration, setRegistration] = useState("")
     const [address,setAddress] = useState("")
  
  
  
     const [patients, setPatient] = useState([])
     const [search,setSearch] = useState("")
  
    const [options,setOptions] = useState("");
    const [url,setUrl] = useState("")
  
    useEffect( ()=>
   
    {
  
  
      fetch('/getScheduleTeacher',{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(data=>{
      setData(data.schedules) 
    
    })
    .catch(err=>{
      console.log(err)
    })
  
     }
    
    
    ,[]) 
  


    const handleWeekendsToggle = () => {
    
        setWeekendsVisible(!weekendsVisible)
      }
    
    
      const handleDateSelect = (selectInfo) => {
    
        
        setStartDate(selectInfo.startStr)
        setEndDate(selectInfo.endStr)
        setallDay(selectInfo.allDay)
        setModal(true)
    
        setCalenderAPI(selectInfo.view.calendar)
       
       
      }
    
     const handleEvents = (events) => {
        setCurrentEvents(events)
      }
    
    
      const PostSchedule = ()=>{
    
    
    
    
        calendarApi.unselect() // clear date selection
    
        if (typeof(title)=='object') {
    
          
    
          calendarApi.addEvent({
            
            
            title:title.label.props.children[4],
            description,
            start: start,
            end: end,
            allDay: allDay
          })
    
    
    
          fetch("/setScheduleTeacher",{
            method:"post",
            headers:{
                "Content-Type":"application/json",
                Authorization: "Bearer " +localStorage.getItem("jwt")
    
            },
            body: JSON.stringify({
              
             
               title:title.label.props.children[4],
               description,
               start: start,
               end: end,
               
            })
            
        }).then(res=>res.json())
        .then(message=>{
          console.log(message)
        })
    
        }
        else {
    
          
    
          calendarApi.addEvent({
            
            
            title:title,
            description,
            start: start,
            end: end,
            allDay: allDay
          })
    
    
    
          fetch("/setScheduleTeacher",{
            method:"post",
            headers:{
                "Content-Type":"application/json",
                Authorization: "Bearer " +localStorage.getItem("jwt")
    
            },
            body: JSON.stringify({
              
             
                
               title:title,
               description,
               start: start,
               end: end,
               
            })
            
        }).then(res=>res.json())
        .then(message=>{
          console.log(message)
        })
    
        }
    
    
      }
    
    
    
      const handleEventClick = (clickInfo) => {
        if (window.confirm(`Are you sure you want to delete the Appointment '${clickInfo.event.title}'`)) {
    
          const postId = clickInfo.event.extendedProps._id
    
          fetch(`/deleteScheduleTeacher/${postId}`,{
            method: "delete",
            headers:{
                Authorization:"Bearer "+localStorage.getItem("jwt")
            }
        }).then(res=>res.json())
        .then(result=>{
          console.log(result)
        })
        .catch(err=>{
          console.log(err)
        })
    
          clickInfo.event.remove()
        }
      }
    
    
    
    
    function renderEventContent(eventInfo) {
      return (
        <>
          <b>{eventInfo.timeText}</b>
          <i>title: {eventInfo.event.title}  </i>
          <br></br>
          <i>{eventInfo.event.extendedProps.description}</i>
        </>
      )
    }
    
  
  



    return (
        <div>
             {/* Nav */}
            <Nav_men/>
            {/* end Nav */}
            <header className="bg-white shadow">
                 <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                     <h1 className="text-3xl font-bold text-gray-900">Plan Your Schedule</h1>
                </div>
            </header>
          
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {/* Add content here */}

                <FullCalendar
    
    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    }}
    initialView='dayGridMonth'
    editable={true}
    selectable={true}
    selectMirror={true}
    dayMaxEvents={true}
    weekends={weekendsVisible}
    events={data} 
    select={handleDateSelect}
    eventContent={renderEventContent}
    eventClick={handleEventClick}
    eventsSet={handleEvents}

  />


{
modal? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setModal(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
           

           
          <form >




        <div className="  sm:rounded-md">
            <div className="">
              
            <div className="col-span-6 sm:col-span-3">
            <span className="px-1 text-sm text-gray-600">Description</span>
              <textarea type="text"
               name="first_name" 
               id="first_name" 
               autoComplete="given-name" 
               className="text-md block px-3 py-2 rounded-lg w-full
               bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
               value = {description}
              onChange={(e)=>setDescription(e.target.value)}
               />
            </div>
   
     
              
            </div>
           

          
          
        </div>
      </form>
          </div>
          
          
          <div className="flex items-center  justify-end p-6 ">
            <button
              className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease"}}
              onClick={() => setModal(false)}
            >
               Cancel
            </button>
           
            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease" }}
               onClick={() => {setModal(false); PostSchedule()}}
            >
              Save 
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}

            </div>  



            {/* Footer */}
            <Footer/>
            {/* end Footer */}
            
        </div>
    )
}

export default ToDo_men
