import React, {useState, useEffect} from  'react';

import {Link} from 'react-router-dom'

import ScholarshipForm from './ScholarshipForm'



function Scholarships() {

  const [data,setData] = useState([])

  console.log(data)

useEffect(()=>{


  fetch('/getScholarship',{
    headers:{
      "Content-Type":"application/json"
        
    }
}).then(res=>res.json())
.then(result=>{
    setData(result.scholarship)
})


},[])



    return (
        <div>
            <header className="bg-white shadow">
<div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
<h1 className="text-3xl font-bold text-gray-900">Scholarships</h1>
</div>
</header>



            <div class="flex flex-wrap w-full h-full">
        <div class="flex flex-col w-full md:w-2/3 bg-gray-200 h-screen">
        <div class="flex-col justify-center items-center text-center  overflow-y-auto ">
   {/* dynamicaaly load code here */}

        {data.map(item=>{

            return(
              <>
               <div class="px-4 py-4 sm:px-6 shadow-sm m-2  bg-white">
                        <div class="flex items-center justify-between">
                            <h1 class="text-lg font-bold text-gray-700  md:truncate">
                               {item.title}
                            </h1>
                        </div>
                        <div class="flex items-center justify-between">
                            <p class="text-md text-gray-700  md:truncate">
                               {item.organization}
                            </p>
                        </div>
                        <div class="flex items-center justify-between">
                            <p class="text-md text-gray-700  md:truncate">
                               {item.body}
                            </p>
                        </div>
                        <div class="flex items-center justify-between">
                            <a href="#" class="text-md  md:truncate">
                              { item.link}
                            </a>
                        </div>
            </div>
              
              </>
            )

        })}







{/* 
   <ScholarshipCard/> */}




</div>
         
        </div>
        <div class="w-1/3 flex flex-col flex-center">
        <h2 class="text-3xl text-center mt-24 font-bold">
        Add A Scholarship Announcment
        </h2>
        <p class=" text-inline text-gray-500 mt-8 text-sm p-2">
        Add A Scholarship Announcment if you have any scholar ship annoucnen sdgskd  yusdhbc uisd gcuidb cug ucb uv guis dbui vuifg viubduicb suidcbsud cuis bcusdbcusbdcuisd csduc sbduc usdc uisd csdbc
        </p>

          <ScholarshipForm/>

       
        </div>
        
        </div>

        </div>
        
    )
}

export default Scholarships
