import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import AssignmentIcon from "@mui/icons-material/Assignment"
import PhoneIcon from "@mui/icons-material/Phone"
import React, { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Peer from "simple-peer"
import {socket} from "../App"
// import "../App.css"
import { useNavigate } from 'react-router-dom';


function Videocall() {
	const [ me, setMe ] = useState("")
	const [ stream, setStream ] = useState()
	const [ receivingCall, setReceivingCall ] = useState(false)
	const [ caller, setCaller ] = useState("")
	const [ callerSignal, setCallerSignal ] = useState()
	const [ callAccepted, setCallAccepted ] = useState(false)
	const [ idToCall, setIdToCall ] = useState("")
	const [ callEnded, setCallEnded] = useState(false)
	const [ name, setName ] = useState("")
	const myVideo = useRef()
	const userVideo = useRef()
	const connectionRef= useRef()
	const navigate = useNavigate();

	const socketid = localStorage.getItem("socketid")

	useEffect(() => {
		navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
			setStream(stream)
				myVideo.current.srcObject = stream
		})

	socket.on("me", (id) => {
			setMe(id)
		})
		setIdToCall(socketid)

		socket.on("callUser", (data) => {
			setReceivingCall(true)
			setCaller(data.from)
			setName(data.name)
			setCallerSignal(data.signal)
		})
	}, [])

	const callUser = (id) => {
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("callUser", {
				userToCall: id,
				signalData: data,
				from: me,
				name: name
			})
		})
		peer.on("stream", (stream) => {
			
				userVideo.current.srcObject = stream
			
		})
		socket.on("callAccepted", (signal) => {
			setCallAccepted(true)
			peer.signal(signal)
		})

		connectionRef.current = peer
	}

	const answerCall =() =>  {
		setCallAccepted(true)
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("answerCall", { signal: data, to: caller })
		})
		peer.on("stream", (stream) => {
			userVideo.current.srcObject = stream
		})

		peer.signal(callerSignal)
		connectionRef.current = peer
	}

	const leaveCall = () => {
		setCallEnded(true)
		try{
			// connectionRef.current.destroy()
			navigate(-1)

		}catch{
			navigate(-1)

		}
		// history('/chat')
	}

	return (
		<>
			{/* <h1 style={{ textAlign: "center", color: '#fff' }}>Zoomish</h1> */}
		{/* <div className="container">
			<div className="video-container">
				<div className="video">
					{stream &&  <video playsInline muted ref={myVideo} autoPlay style={{ width: "300px" }} />}
				</div>
				{socketid}
				<div className="video">
					{callAccepted && !callEnded ?
					<video playsInline ref={userVideo} autoPlay style={{ width: "300px"}} />:
					null}
				</div>
			</div>
			<div className="myId">
				<TextField
					id="filled-basic"
					label="Name"
					variant="filled"
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ marginBottom: "20px" }}
				/>
				<CopyToClipboard text={me} style={{ marginBottom: "2rem" }}>
					<Button variant="contained" color="primary" startIcon={<AssignmentIcon fontSize="large" />}>
						Copy ID
					</Button>
				</CopyToClipboard>

				<TextField
					id="filled-basic"
					label="ID to call"
					variant="filled"
					value={idToCall}
					onChange={(e) => setIdToCall(e.target.value)}
				/>
				<div className="call-button">
					{callAccepted && !callEnded ? (
						<Button variant="contained" color="secondary" onClick={leaveCall}>
							End Call
						</Button>
					) : (
						<IconButton color="primary" aria-label="call" onClick={() => callUser(idToCall)}>
							<PhoneIcon fontSize="large" />
						</IconButton>
					)}
					{idToCall}
				</div>
			</div>
			<div>
				{receivingCall && !callAccepted ? (
						<div className="caller">
						<h1 >{name} is calling...</h1>
						<Button variant="contained" color="primary" onClick={answerCall}>
							Accept
						</Button>
					</div>
				) : null}
			</div>
		</div> */}

<div className=" flex flex-col items-center justify-center min-h-screen m-4 bg-gray-100">
  <div className="video-container grid grid-cols-2 gap-4 w-full ">
    <div className="video bg-black flex justify-center items-center">
      {stream && (
        <video
          playsInline
          muted
          ref={myVideo}
          autoPlay
          className="w-full h-full object-cover"
        />
      )}
    </div>
    <div className="video bg-black flex justify-center items-center">
		
      {callAccepted && !callEnded ? (
        <video
          playsInline
          ref={userVideo}
          autoPlay
          className="w-full h-full object-cover"
        />
      ) : null}
    </div>
	{/* <Button variant="contained" color="secondary" onClick={leaveCall}>
          End Call
        </Button> */}
  </div>
  <div className="myId mt-8 flex flex-col items-center">
   
    <div className="call-button mt-4">
      {callAccepted && !callEnded ? (
        <Button variant="contained" color="secondary" onClick={leaveCall}>
          End Call
        </Button>
      ) : (

		<Button variant="contained" color="secondary" onClick={()=>callUser(idToCall)}>
          Ask to Join
        </Button> 
        // <IconButton color="primary" aria-label="call" onClick={() => callUser(idToCall)}>
        //   <PhoneIcon fontSize="large" />
        // </IconButton>
      )}
      {idToCall}
    </div>
  </div>
  <div>
    {receivingCall && !callAccepted ? (
      <div className="caller mt-8">
        <h1>{name} is calling...</h1>
        <Button variant="contained" color="primary" onClick={answerCall}>
          Accept
        </Button>
      </div>
    ) : null}
  </div>
</div>

		</>
	)
}

export default Videocall
