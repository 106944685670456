
import React,{useState,useEffect,useContext} from 'react'
import {useParams, useNavigate, Link} from 'react-router-dom'
import { List, Avatar, Typography } from 'antd';
import {UserContex} from '../App'
import Nav from './Nav'
import Footer from './Footer'
import Iframe from 'react-iframe'







function SinglePage() {

    const history = useNavigate()
    const {postId} = useParams()
    const [post,setPost] = useState([])
    const [text, setText] = useState("")

   


    useEffect(()=>{

        fetch(`/singlepost/${postId}`,{
            headers:{
                Authorization: "Bearer " +localStorage.getItem("jwt")
                
            }
        }).then(res=>res.json())
        .then(result=>{
          setPost(result.post)

          console.log(result.post)
        })
         
       
        
        
        


    },[])



    const addCourse = ()=>{

        fetch('/addCourse',{
            method:"put",
            headers:{
                "Authorization":"Bearer "+localStorage.getItem("jwt"),
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                courseId:postId
                
            })
        }).then(res=>res.json())
            .then(result=>{
                
            
               }).catch(err=>{
                   console.log(err)
               })


    }


    const makeComment = ()=>{

        fetch('/comment',{
            method:"put",
            headers:{
                "Authorization":"Bearer "+localStorage.getItem("jwt"),
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                postId,
                text
            })
        }).then(res=>res.json())
            .then(result=>{

                window.location.reload(false)

                
                const newData = post.map(item=>{
                    if(item._id == result._id){
                        return result
                    }else{
                        return item
                    }

                    
                })
                setPost(newData)

               }).catch(err=>{
                   console.log(err)
               })
    }





    return (
        <div>
                     {/* Nav */}
                     <Nav/>
            {/* end Nav */}
            <header className="bg-white shadow">
                 <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-row">
                     <h1 className="text-3xl font-bold text-gray-900">Start Learning</h1>
                     <button type="button" class="py-2 ml-2 px-12 bg-Our_purple_dark  text-black w-500px transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg text-white"
                     
                     onClick={()=>addCourse()}
                     >
                         Bookmark 
                    </button>
                  
                </div>
            </header>



                {post?  <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {/* Add content here */}
                <h1 class="font-bold text-4xl text-center ">{post.title}</h1>
                <p class="  text-center text-gray-400">{}</p>
                <p class="  text-center text-gray-400 my-8">{post.body}</p>

                <div className="max-w-7xl h-full bg-gray-200 mx-auto py-2 sm:px-6 lg:px-4">
                    {/* Video Loads here */}

                    {/* <Iframe url="localhost:1111/video/video-1624198809231.mov" 
        width="100%"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/> */}


<div className="postPage" style={{ width: '100%'}}>
            <video style={{ width: '100%' }} src={`http://localhost:1111/${post.url}`} controls></video>

            <List.Item
                actions={[]}
            >
            
                <div></div>
            </List.Item>

        </div>

                </div>
                <div className="max-w-7xl h-full bg-white shadow-md mx-auto py-6 sm:px-6 lg:px-8">
                <div className="col-span-6 sm:col-span-3 flex flex-row justify-center">
                      
                      

                      
                      <input
                        type="text"
                        placeholder="Ask A Question"
                        className="appearance-none border rounded-xl border-gray-300 w-2/3 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                        value={text}
                        onChange={(e)=>setText(e.target.value)}


                      />
                          <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-xl text-white bg-Our_purple_dark hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                        onClick={()=>makeComment()}
                  >
                    Send
                  </button>
                    </div>
                </div> 

                <div className="max-w-7xl h-full bg-white shadow-md mx-auto py-6 sm:px-6 lg:px-8">
                     <div className="col-span-6 sm:col-span-3 flex flex-col justify-center">


                            {
                                post.comments ?

                                post.comments.map(item=>{

                                    return(

                                         <div className="col-span-6 sm:col-span-3 mb-2 border-b-2">
                                <label className="text-sm text-gray-600 pb-2">{item.postedBy.name}</label>
                                <p class=" pb-4">{item.text}</p>
                            </div> 
                                    )

                                }): null

                            }



                        
                            
                            
                    </div>
                </div>
            
            </div>  :<h1>Loading.....</h1>

    }


          
           

            {/* Footer */}
            <Footer/>
            {/* end Footer */}
            
        </div>
            
    
    )
}

export default SinglePage
