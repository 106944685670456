import React from 'react'
import Nav from "./Nav"
import Footer from "./Footer"


function Regulated() {
    return (
        <div>
        {/* Nav */}
       <Nav/>
       {/* end Nav */}
       <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900 text-center">Select Your Grade</h1>
           </div>
       </header>
     
       <div className="max-w-full mx-auto py-6 sm:px-6 lg:px-8 bg-gray-100" >
       <h1 className="text-3xl font-bold text-gray-900 text-center my-2">High Seconary School</h1>
       <div class="sm:flex flex-wrap justify-center items-center text-center gap-8 my-8 ">
              {/* dynamicaaly load code here */}
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8 ">XII</h1>
              </div>
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">XI</h1>
              </div>
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">X</h1>
              </div>
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">IX</h1>
              </div>
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">VIII</h1>
              </div>
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">VII</h1>
              </div>
      
        </div>
       <h1 className="text-3xl font-bold text-gray-900 text-center my-2">Middle Seconary School</h1>
       <div class="sm:flex flex-wrap justify-center items-center text-center gap-8 my-8">
              {/* dynamicaaly load code here */}
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">VI</h1>
              </div>
      
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">V</h1>
              </div>
      
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">IV</h1>
              </div>
      
      
        </div>
       <h1 className="text-3xl font-bold text-gray-900 text-center my-2"> Primary School</h1>
       <div class="sm:flex flex-wrap justify-center items-center text-center gap-8 my-8">
              {/* dynamicaaly load code here */}
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">III</h1>
              </div>
      
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">II</h1>
              </div>
      
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">I</h1>
              </div>
      
              <div className="w-32 h-32 shadow-md rounded-xl bg-white">
                  <h1 class="font-bold text-6xl text-center text-Our_purple_dark py-8">PP</h1>
              </div>
      
      
        </div>

 

       </div>  



       {/* Footer */}
       <Footer/>
       {/* end Footer */}
       
   </div>
    )
}

export default Regulated
