
import React, {useState, useEffect} from  'react';

import {Link} from 'react-router-dom'

import Header from './Header';
import Footer from './Footer';
import WorkingSection from './WorkingSection';
import TeamSection from './TeamSection';
import ContactSection from './ContactSection';
import Nav from './Nav'
import ScholarshipCard from './ScholarshipCard';

export default function Landing() {


    const [data,setData] = useState([])

    useEffect(()=>{


        fetch('/getScholarship',{
          headers:{
            "Content-Type":"application/json"
              
          }
      }).then(res=>res.json())
      .then(result=>{
          setData(result.scholarship)
      })
      
      
      },[])
    return (
        <>
            <div className="absolute w-full z-20">
               <Nav/>
            </div>
            <main>
                <Header />
                <WorkingSection />
                <TeamSection />
                <ContactSection />
                <div className="pt-20 m-8 ">
                     <div className="container max-w-7xl mx-auto px-4 text-center">
                     <h1 class="font-bold text-4xl text-center mb-16">Scholarship</h1>
                     <div class="flex-col bg-gray-200 rounded-2xl justify-center items-center text-center  overflow-y-auto h-64 ">
                        {/* dynamicaaly load code here */}
                        {data.map(item=>{

return(
  <>
   <div class="px-4 py-4 sm:px-6 shadow-sm m-2  bg-white ">
            <div class="flex items-center justify-between">
                <h1 class="text-lg font-bold text-gray-700  md:truncate">
                   {item.title}
                </h1>
            </div>
            <div class="flex items-center justify-between">
                <p class="text-md text-gray-700  md:truncate">
                   {item.organization}
                </p>
            </div>
            <div class="flex items-center justify-between">
                <p class="text-md text-gray-700  md:truncate">
                   {item.body}
                </p>
            </div>
            <div class="flex items-center justify-between">
                <a href="#" class="text-md  md:truncate">
                  { item.link}
                </a>
            </div>
</div>
  
  </>
)

})}


                        </div>
                     </div>
                 </div>
                 

            </main>
            
            <Footer/>
           
        </>
    );
}
