// import Card from '@material-tailwind/react/Card';
// import CardImage from '@material-tailwind/react/CardImage';
// import CardBody from '@material-tailwind/react/CardBody';
// import Icon from '@material-tailwind/react/Icon';
// import H4 from '@material-tailwind/react/Heading4';
// import H6 from '@material-tailwind/react/Heading6';
// import LeadText from '@material-tailwind/react/LeadText';
// import Paragraph from '@material-tailwind/react/Paragraph';
import StatusCard from './StatusCard';


export default function WorkingSection() {
    return (
        <section className="pb-2 bg-gray-100 -mt-32">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap relative z-50">
                    <StatusCard />
                </div>
            </div>
        </section>
    );
}
