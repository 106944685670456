// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import TextField from "@mui/material/TextField";
// import AssignmentIcon from "@mui/icons-material/Assignment";
// import PhoneIcon from "@mui/icons-material/Phone";
// import React, { useEffect, useRef, useState } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import Peer from "simple-peer";
// import { socket } from "../App";
// import {Link, useNavigate,useParams} from 'react-router-dom'




// function ScheduledCall() {
  
//   const [ me, setMe ] = useState("")
// 	const [ stream, setStream ] = useState()
// 	const [ receivingCall, setReceivingCall ] = useState(false)
// 	const [ caller, setCaller ] = useState("")
// 	const [ callerSignal, setCallerSignal ] = useState()
// 	const [ callAccepted, setCallAccepted ] = useState(false)
// 	const [ idToCall, setIdToCall ] = useState("")
// 	const [ callEnded, setCallEnded] = useState(false)
// 	const [ name, setName ] = useState("")
// 	const myVideo = useRef()
// 	const userVideo = useRef()
// 	const connectionRef= useRef()
//   const {dzongkhaid} = useParams()

// 	useEffect(() => {
// 		navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
// 			setStream(stream)
// 				myVideo.current.srcObject = stream
// 		})

// 	socket.on("me", (id) => {
// 			setMe(id)
// 		})

// 		socket.on("callUser", (data) => {
// 			setReceivingCall(true)
// 			setCaller(data.from)
// 			setName(data.name)
// 			setCallerSignal(data.signal)
// 		})
// 	}, [])

// 	const callUser = (id) => {
// 		const peer = new Peer({
// 			initiator: true,
// 			trickle: false,
// 			stream: stream
// 		})
// 		peer.on("signal", (data) => {
// 			socket.emit("callUser", {
// 				userToCall: id,
// 				signalData: data,
// 				from: me,
// 				name: name
// 			})
// 		})
// 		peer.on("stream", (stream) => {
			
// 				userVideo.current.srcObject = stream
			
// 		})
// 		socket.on("callAccepted", (signal) => {
// 			setCallAccepted(true)
// 			peer.signal(signal)
// 		})

// 		connectionRef.current = peer
// 	}

// 	const answerCall =() =>  {
// 		setCallAccepted(true)
// 		const peer = new Peer({
// 			initiator: false,
// 			trickle: false,
// 			stream: stream
// 		})
// 		peer.on("signal", (data) => {
// 			socket.emit("answerCall", { signal: data, to: caller })
// 		})
// 		peer.on("stream", (stream) => {
// 			userVideo.current.srcObject = stream
// 		})

// 		peer.signal(callerSignal)
// 		connectionRef.current = peer
// 	}

// 	const leaveCall = () => {
// 		setCallEnded(true)
// 		connectionRef.current.destroy()
// 	}


//   // const StartCall=()=>{

//   //   fetch("/startsession",{
//   //     method:"put",
//   //     headers:{
//   //         "Content-Type":"application/json",
//   //         Authorization: "Bearer " +localStorage.getItem("jwt")
  
//   //     },
//   //     body: JSON.stringify({
//   //       postId:dzongkhaid,
//   //       callid:me
      
//   //     })
      
//   // }).then(res=>res.json())
//   // .then(data=> {
     
//   //     if(data.error){
//   //         console.log("error")
//   //     }
//   //     else{
//   //         // history('/MentorDashboard')
//   //     }
//   // })
//   // .catch(err=>{
//   //     console.log(err)
//   // })
  
//   //  }
  

// 	return (
// 		<>
// 			<h1 style={{ textAlign: "center", color: '#fff' }}>Zoomish</h1>
// 		<div className="container">
// 			<div className="video-container">
// 				<div className="video">
// 					{stream &&  <video playsInline muted ref={myVideo} autoPlay style={{ width: "300px" }} />}
// 				</div>
// 				<div className="video">
// 					{callAccepted && !callEnded ?
// 					<video playsInline ref={userVideo} autoPlay style={{ width: "300px"}} />:
// 					null}
// 				</div>
// 			</div>

// 			<div className="myId">
// 				<TextField
// 					id="filled-basic"
// 					label="Name"
// 					variant="filled"
// 					value={name}
// 					onChange={(e) => setName(e.target.value)}
// 					style={{ marginBottom: "20px" }}
// 				/>
// 				<CopyToClipboard text={me} style={{ marginBottom: "2rem" }}>
// 					<Button variant="contained" color="primary" startIcon={<AssignmentIcon fontSize="large" />}>
// 						Copy ID
// 					</Button>
// 				</CopyToClipboard>

// 				<TextField
// 					id="filled-basic"
// 					label="ID to call"
// 					variant="filled"
// 					value={idToCall}
// 					onChange={(e) => setIdToCall(e.target.value)}
// 				/>
// 				<div className="call-button">
// 					{callAccepted && !callEnded ? (
// 						<Button variant="contained" color="secondary" onClick={leaveCall}>
// 							End Call
// 						</Button>
// 					) : (
// 						<IconButton color="primary" aria-label="call" onClick={() => callUser(idToCall)}>
// 							<PhoneIcon fontSize="large" />
// 						</IconButton>
// 					)}
// 					{idToCall}
// 				</div>


// {/* <button

// type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
// onClick={()=>{StartCall()}}
// >
//     Start
// </button> */}

// 			</div>
// 			<div>
// 				{receivingCall && !callAccepted ? (
// 						<div className="caller">
// 						<h1 >{name} is calling...</h1>
// 						<Button variant="contained" color="primary" onClick={answerCall}>
// 							Answer
// 						</Button>
// 					</div>
// 				) : null}
// 			</div>
// 		</div>
// 		</>
// 	)
// }

// export default ScheduledCall;


import React, { useEffect, useState } from 'react';

const ScheduledCall = () => {
  const [meetingDetails, setMeetingDetails] = useState(null);

  // Function to initiate the OAuth flow
  const initiateZoomOAuth = () => {
    window.location.href = '/zoom';
  };

  // Extract the authorization code from the URL if available
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Make an API call to your backend to complete the OAuth flow and create the meeting
      fetch(`/zoom?code=${code}`)
        .then((response) => response.json())
        .then((data) => {
          setMeetingDetails(data);
          console.log('Meeting Created:', data);
        })
        .catch((error) => {
          console.error('Error creating meeting:', error);
        });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-8">Zoom Meeting Integration</h1>
      <button
        onClick={initiateZoomOAuth}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition"
      >
        Login with Zoom & Create Meeting
      </button>

      {meetingDetails && (
        <div className="mt-8 p-6 bg-white rounded-lg shadow-lg text-left">
          <h2 className="text-2xl font-semibold mb-4">Meeting Created Successfully</h2>
          <p className="mb-2"><strong>Topic:</strong> {meetingDetails.topic}</p>
          <p className="mb-2"><strong>Start Time:</strong> {meetingDetails.start_time}</p>
          <p className="mb-2">
            <strong>Join URL:</strong>{' '}
            <a
              href={meetingDetails.join_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Join Meeting
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default ScheduledCall;
