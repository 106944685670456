import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
import SearchIcon from '@mui/icons-material/Search';
import {UserContex} from '../App'


function Search() {

  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)




  useEffect(()=>{

    fetch('/getTeacher',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setUserDetails(result.post)
  })


  },[])



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-teacher',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setUserDetails(results.user)
      })


  }
    

  const startChat =(teacher)=>{

    fetch('/createchat',{

      method:"post",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        teacher
      })

    }).then(res=> res.json())
      .then(results=>{

        history('/chat')
        
      })



  }

    return (
        <div>
             {/* Nav */}
            <Nav/>
            {/* end Nav */}
            <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Search For Tachers</h1>
          <div class="flex relative ">
          <span class="mt-2 inline-flex  items-center px-3 text-sm">
            <SearchIcon/>        
    </span>

        
    
    <input type="text" id="" class=" mt-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="" placeholder="Enter Search"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}
    
    />
    
    </div>
        </div>

        
        
      </header>
      <main>
            <div class="sm:flex flex-wrap justify-center items-center text-center gap-8">
              {/* dynamicaaly load code here */}

              {
                userDetails.map(item=>{

                  return(


                    // <Link to={"/singlepage/"+item._id}>

                    
                    <div class="flex items-center justify-center px-5 py-5 m-10">
                <div class="w-full mx-auto max-w-xl rounded-lg bg-white shadow-lg px-5 pt-5 pb-10 text-gray-800 ">
                    <div class="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                        <a href="#" class="block relative">
                            <img alt="profil" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" class="mx-auto object-cover rounded-full h-20 w-20 "/>
                        </a>
                    </div>


                    <div class="w-full mb-10">
                    < h2 class="text-lg font-bold text-black text-center px-5">
                            {item.name}
                        </h2>
                        
                    </div>
                    <div class="w-full">
                        <p class="text-md text-indigo-500 font-bold text-center">
                        {item.name}
                        </p>
                        <p class="text-xs text-gray-500  text-center">
                          {item.email}
                        </p>
                    </div>
                    <svg class="h-8 w-8 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    onClick={()=>{startChat(item._id)}}
                    >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"/>
</svg>

                </div>


            </div>
            // </Link>
                  )

                })
              }

            </div>

      </main>
            {/* Footer */}
            <Footer/>
            {/* end Footer */}
        </div>
    )
}

export default Search
