import React, {useState, useEffect} from  'react';

import {Link} from 'react-router-dom'

export default function ContactSection() {
    return (
        <section className="pb-20 relative block bg-gray-100">
                <h1 class="font-bold text-4xl text-center py-16">Search For Subjects</h1>
             <div class="sm:flex flex-wrap justify-center items-center text-center gap-4">
               
               
               <Link to ='/english'>
                <div class=" h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg  ">
                   
                   
                   
                  
                   
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* < AddIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        English
                    </h3> 
                </div>
</Link>





                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Dzongkha
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Maths
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Biology
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Physics
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Chemistry
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        History
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Geography
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Economics
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Arts
                    </h3>
            
                </div>
                <div class="h-64 w-64  px-4 py-4 bg-white shadow-lg rounded-lg ">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <HelpOutlineIcon/> */}
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Commerce
                    </h3>
            
                </div>
            
          
            </div>
        </section>
    );
}
