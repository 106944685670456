import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import { UploadIcon } from '@heroicons/react/outline';

import Nav_men from "./Nav_men"


function CreateCourseForm() {

          const history = useNavigate()
          const [title,setTitle] = useState("")
          const [body,setBody] = useState("")
          const [url,setUrl] = useState("")
          const [subject,setSubject] = useState("")
          const [category,setCategory] = useState("Video")



console.log(url)
  const PostData=()=>{

    fetch("/addPost",{
      method:"post",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        title,
        body,
        url,
        subject,
        category
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
          console.log("error")
      }
      else{
          history.push('/MentorDashboard')
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }


   const uploadppt=({target:{files}})=>{

   
    let data = new FormData()

    for(const file of files){

   data.append('ppt', file)
   data.append('name',file.name)
}
       fetch("/ppt", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

         for(const path of data.message){
          setUrl(path.path)
          console.log(path.path)
         }

       
     })
     .catch(err=>{
         console.log(err)
     })

}
   const uploadvid=({target:{files}})=>{

   
    let data = new FormData()

    for(const file of files){

   data.append('video', file)
   data.append('name',file.name)
}
       fetch("/video", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

         for(const path of data.message){
          setUrl(path.path)
          console.log(path.path)
         }

       
     })
     .catch(err=>{
         console.log(err)
     })

}


   




    return (
        <div>
          <Nav_men/>
              <div className="max-h-screen  flex flex-col justify-center bg-cover bg-back">
            <div className="max-w-lg w-full mx-auto mt-4 bg-white p-8 border-gray-300" >
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                 
                  <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700 ">
                        Topic
                      </label>
                      <input
                        type="text"
                        value={title}
                        onChange={(e)=>setTitle(e.target.value)}
                     
                        className="appearance-none border border-gray-300 w-full py-2 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700 ">
                        Description
                      </label>
                      <input
                        type="text"
                        value={body}
                        onChange={(e)=>setBody(e.target.value)}

                     
                        className="appearance-none border border-gray-300 w-full py-2 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                       <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <select

                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                        id="Subject"
                        name="Subject"
                        
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="English">English</option>
                        <option value="Dzongkha" >Dzongkha</option>
                        <option value="Maths">Maths</option>
                        <option value="Biology" >Biology</option>
                        <option value="Chemistry">Chemistry</option>
                        <option vlaue="Physics">Physics</option>
                        <option vlaue="Geography">Geography</option>
                        <option value="History">History</option>
                        <option value="Arts">Arts</option>
                        <option value="Economics">Economics</option>
                        <option value="Computer Science">Computer Science</option>
                        <option value="Agriculture" >Agriculture</option>
                      </select>
                    </div>
                    </div> <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Upload Type
                      </label>
                      <select
                        id="Subject"
                        name="Subject"
                        value={category}
                        onChange={(e)=>setCategory(e.target.value)}
                        
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="Video">Video</option>
                        <option value="File">File</option>
                      </select>
                    </div>

                    {category=="Video"?
                    
                    <div className="col-span-6 ">
                       <label className="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload Video</span>
        <input type='file' className="hidden" 
        onChange={uploadvid}
        />
        
    </label>
         
                     
                      
                     
                    
                    </div>:  <div className="col-span-6 ">
                       <label className="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload File</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
         
                     
                      
                     
                    
                    </div>
                    
                  }             

                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                     onClick={()=>PostData()}
                
                
                >
                    Create Course
                  </button>
                </div>
              </div>
            </div>
        </div>
        </div>
    )
}

export default CreateCourseForm
