import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link} from 'react-router-dom'
import Title from './Title';
import TeamCard from './TeamCard';
import SubjectsCards from './SubjectsCards';

export default function TeamSection() {

    const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState([])


  useEffect(()=>{

    fetch('/getLatestPost',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setUserDetails(result.post)
  })


  },[])


    return (
        <section className="pt-20  ">
            <div className="container max-w-7xl mx-auto px-4 text-center">
                <h1 class="font-bold text-4xl text-center mb-16">New Courses</h1>
                <div class="sm:flex flex-wrap justify-center items-center text-center gap-8">
              {/* dynamicaaly load code here */}
              {
                userDetails.map(item=>{

                  return(


                    <Link to={"/singlepage/"+item._id}>

                    
                    <div class="flex items-center justify-center px-5 py-5 m-10">
                <div class="w-full mx-auto max-w-xl rounded-lg bg-white  shadow-lg px-5 pt-5 pb-10 text-gray-800 ">
                    <div class="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                        <a href="#" class="block relative">
                            <img alt="profil"                                 src="https://images.unsplash.com/photo-1573496799652-408c2ac9fe98?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
class="mx-auto object-cover rounded-full h-20 w-20 "/>
                        </a>
                    </div>


                    <div class="w-full mb-10">
                    < h2 class="text-lg font-bold text-black text-center px-5">
                            {item.title}
                        </h2>
                        <p class="text-sm text-gray-600  text-center px-5">
                            {item.body}
                        </p>
                    </div>
                    <div class="w-full">
                        <p class="text-md text-indigo-500 font-bold text-center">
                        {item.teacher.name}
                        </p>
                        <p class="text-xs text-gray-500  text-center">
                          {item.teacher.email}
                        </p>
                    </div>
                </div>
            </div>
            </Link>
                  )

                })
              }



            </div>
            <Link to="/search">
            <button  type="button" class="mt-4 py-2 mb-8 px-14 bg-Our_purple_dark text-white w-500px transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        Explore More 
                    </button>
            </Link>
            
            </div>
        </section>
    );
}
