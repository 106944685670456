import React, {useState, useEffect} from  'react';

import {Link, useNavigate} from 'react-router-dom'

function ScholarshipForm() {

  const history = useNavigate()
  const [title,setTitle] = useState("")
  const [organization,setOrganization] = useState("")
  const [body,setBody] = useState("")
  const [link,setLink] = useState("")
  const [phone,setPhone] = useState("")

  const PostData =()=>{

   
    fetch("/addScholarship",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
        title,
        organization,
        body,
        link,
        phone
          
      })
      
  }).then(res=>res.json())
  .then(data=> {

    if(data.err){

    }
    else{
      history('/scholarships')
    }
     
    window.location.reload(false)
      
  })
  .catch(err=>{
      console.log(err)
  })

       }


    return (
        <div>
        
             <div className="max-h-screen  flex flex-col justify-center">
         
            <div className="max-w-lg w-full mx-auto mt-4 bg-white p-8 border-gray-300" >
        
            
            
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                   

                   

                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700 ">
                        Title Of Scholarship
                      </label>
                      <input
                        type="text"
                        value={title}

                        onChange={(e)=>setTitle(e.target.value)}
                     
                        className="appearance-none border border-gray-300 w-full py-2 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                        Organization
                      </label>
                      <input
                        type="text"
                        value={organization}

                        onChange={(e)=>setOrganization(e.target.value)}

                     
                        className="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                        Body
                      </label>
                      <input
                        type="text"
                        value = {body}
                        onChange={(e)=>setBody(e.target.value)}

                   
                        className="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                        Phone No.
                      </label>
                      <input
                        type="text"
                        value = {phone}
                        onChange={(e)=>setPhone(e.target.value)}

                   
                        className="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
               

                    <div className="col-span-6 ">
                      <label htmlFor="street_address" className="block text-sm font-medium text-gray-700">
                        Link
                      </label>
                      <input
                        type="text"
                        value = {link}
                        onChange={(e)=>setLink(e.target.value)}

                       
                        className="appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                     
                    onClick={()=>PostData()}
                 
                 >
                    Add Scholarship
                  </button>
                </div>
              </div>
           
            </div>
        </div>
            
        </div>
    )
}

export default ScholarshipForm
