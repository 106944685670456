import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate, useParams,useLocation} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
// import SearchIcon from '@material-ui/icons/Search';
import {UserContex} from '../App'


function ClearDoubtsTeacher() {
  const {subjectid} = useParams()
  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [title,setTitle] = useState('')
  const [body,setbody] = useState('')
  const [url,setUrl] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const [data, setData] = useState([])
  const history = useNavigate()
  // const {state, dispatch}= useContext(UserContex)
  const [postdoubts,setPostDoubts] = useState(false)
  const [progress,setProgress] = useState(false)

  const [info,setInfo] = useState({})
  // console.log("SRATE",query)

  // const { subject, grade } = props.location.state;

  useEffect(()=>{

    fetch(`/getsubjectDoubt/${subjectid}`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.post)
  })

    fetch(`/getSingleSubject/${subjectid}`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setInfo(result.post)
  })


  },[])





  const PostData=()=>{

    fetch("/addDoubt",{
      method:"post",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        title,
        body,
        url,
        subject:subjectid
        // category
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
          console.log("error")
      }
      else{
          // history.push('/')
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }

  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-post',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setUserDetails(results.user)
      })


  }
    
  const uploadvid=({target:{files}})=>{

   
    let data = new FormData()

    for(const file of files){

   data.append('video', file)
   data.append('name',file.name)
}
       fetch("/video", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

         for(const path of data.message){
          setUrl(path.path)
          console.log(path.path)
         }

       
     })
     .catch(err=>{
         console.log(err)
     })

}



//   const uploadppt=({target:{files}})=>{

//    setProgress(true)
//     let data = new FormData()

//     for(const file of files){

//    data.append('ppt', file)
//    data.append('name',file.name)
// }
//        fetch("/ppt", {

//          method:"post",
//          body: data
//      })
//      .then(res=>res.json())
//      .then(data=>{

//          for(const path of data.message){
//           setUrl(path.path)
//           console.log(path.path)
//          }

       
//      })
//      .catch(err=>{
//          console.log(err)
//      })

// }

    return (
        <div>
             {/* Nav */}
            <Nav/>
            {/* end Nav */}
            <header className="bg-white shadow">
   
        
        
      </header>
      <main>
          
<div class="w-full p-2 bg-white">
    <div class="flex flex-col md:flex-row items-end justify-between mb-4 mt-2 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Doubts for {info?`${info.grade } ${info.subject}`:""}
            </p>
          
        </div>
       
        </div>

        <div class="text-end mb-4">
            <form class="flex flex-col justify-center w-full max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div class=" relative ">
                    <input type="text" id="&quot;form-subscribe-Search" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Enter a title"/>
                    </div>
                    <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                        Search
                    </button>
                </form>
            </div>

   


        {/* <div class="mt-6  flex justify-center items-center">
 <button
  onClick={()=>setPostDoubts(!postdoubts)} 
  type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex mb-6 justify-center items-center border-purple-300  bg-none hover:bg-purple-500   text-purple-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-purple-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
  
     Post Your Doubts
     <svg class="h-8 w-8  ml-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
</svg>
 </button>
 </div> */}


{postdoubts?
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Post Doubts  </h1>
          </div>
          <div>


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Title</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Title"
value={title}
onChange={(e)=>setTitle(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Question</span>
<textarea class="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Enter your question" name="comment" rows="5" cols="40"
value={body}
onChange={(e)=>setbody(e.target.value)}
>
    </textarea>
</div>

{/* <div class="grid max-w-xl grid-cols-2 gap-4 "> */}



{/* </div> */}


  

<div className="col-span-6 ">
                       <label className="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload File</span>
        <input type='file' className="hidden" 
        onChange={uploadvid}
        />
        
    </label>
         
                     
                      
                     
                    
                    </div>
 
     



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setPostDoubts(false);PostData()}}

      > Post Doubt</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setPostDoubts(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">


  

</div>
          </div>
        </div>
  :<></>}


        <div class="grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-3">
           
            {data?data.map(item=>{

              return(

                <Link to={`/singledoubtteacher/${item._id}`}>
                <div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src={item.url? `${item.url}`: "/images/blog/6.jpg" }class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        <p class="font-medium text-indigo-500 text-md">
                            {item.title}
                        </p>
                       
                        <p class="font-light text-gray-400  text-md">
                            {item.body.substring(0,50)} ...more
                        </p>
                        <div class="flex items-center mt-4">
                            <a href="#" class="relative block">
                                <img alt="profil" src="/images/person/6.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                            </a>
                            <div class="flex flex-col justify-between ml-4 text-sm">
                                <p class="text-gray-800 ">
                                   {item.student.name}
                                </p>
                                <p class="text-gray-400 ">
                                    {item.createdAt.substring(0,10)}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            </Link>

              )

            }):<></>}
          


        </div>
    </div>


      </main>
            {/* Footer */}
            <Footer/>
            {/* end Footer */}
        </div>
    )
}

export default ClearDoubtsTeacher
