import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
function LinkPage() {
    return (
        <div>
        {/* Nav */}
       <Nav/>
       {/* end Nav */}
       <header className="bg-white shadow">
   <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
     <h1 className="text-3xl font-bold text-gray-900">Link</h1>
   </div>
 </header>
 <main>
       
       <div class="sm:flex flex-wrap justify-center items-center text-center gap-4 bg-gray-200">
         {/* dynamicaaly load code here */}
   
         <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href=" http://www.education.gov.bt/" class="block relative">
                    <img alt="profil" src={require("../assets/img/sh.jpg").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">Ministry Of Education</a> 
         </div>

         <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://www.rub.edu.bt/index.php/en/" class="block relative">
                    <img alt="profil" src={require("../assets/img/rub.jpg").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">RUB</a> 
         </div>
         <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="http://www.bcsea.bt/" class="block relative">
                    <img alt="profil" src={require("../assets/img/bc.png").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">BCSEA</a> 
         </div>
         </div>
     
         <div class="sm:flex flex-wrap justify-center items-center text-center gap-4 bg-gray-200">
        <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://cst.edu.bt/index.php/en/" class="block relative">
                    <img alt="profil" src={require("../assets/img/CST.png").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">CST</a> 
                </div>
                <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://www.pce.edu.bt/" class="block relative">
                    <img alt="profil" src={require("../assets/img/Paro.jpg").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">Paro College Of Education</a> 
                </div>
                <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://www.sherubtse.edu.bt/" class="block relative">
                    <img alt="profil" src={require("../assets/img/sherab.png").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">Sherabtshue College </a> 
                </div>
                <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://cst.edu.bt/index.php/en/" class="block relative">
                    <img alt="profil" src={require("../assets/img/sam.jpg").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">SCE</a> 
                </div>
                <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://cst.edu.bt/index.php/en/" class="block relative">
                    <img alt="profil" src={require("../assets/img/cnr.jpeg").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">CNR</a> 
                </div>
                <div class="w-36 h-36 shadow-md mb-8 bg-white rounded-xl">
                
                <a href="https://cst.edu.bt/index.php/en/" class="block relative">
                    <img alt="profil" src={require("../assets/img/jig.gif").default} class="mt-2 mx-auto object-cover rounded-full h-20 w-20 "/>
                </a>
        
                <a href="" class="text-xl font-bold text-gray-600">JNEC</a> 
                </div>
        </div>
 </main>
       {/* Footer */}
       <Footer/>
       {/* end Footer */}
   </div>
    )
}

export default LinkPage
